@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table td {
    padding: 5px;
    border: 1px solid #ddd;
}

.table table {
    position: relative;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin: auto;
}

.MuiExpansionPanelDetails-root {
    padding: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-right: auto;
    margin-top: -8px;

}

.table tbody tr td:first-of-type {
    background-color: #eee;
    position: -webkit-sticky;
    position: sticky;
    left: -1px;
    text-align: left;
}

.container2 {
    padding: 10px;
    border: 1px solid black;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    display: block;
    height: 200px;
    margin-top: 1%;
    border-radius: 10px;
}

.headline {
    /* position: absolute; */
    font-size: 1.2em;
    padding: 0.8em;
    display: block;
    /* background-color: rgb(186, 77, 77); */
    text-align: left;
    /* margin-top: 50px; */
    /* padding-top: 100px; */
}

.addpatients {
    /* display: flex; */
    margin-top: 48px;
    width: 100%;
}

.container2 input:focus {
    outline: none;
    border: none;
}

.inputs {
    position: absolute;
}

.MuiTooltip-tooltip {
    font-size: 15px !important;
}

.order_container {
    /*background-color: #f44336;*/
min-height: 300px;

}
/*.order_header {*/

/*    display: flex;*/
/*    !*justify-content: space-between;*!*/
/*}*/

/*.order_title {*/
/*    border-radius: 4px;*/
/*    padding: 8px 12px;*/
/*    margin-left: 8px;*/
/*    font-size: 20px;*/
/*    width:350px;*/
/*    height: 24px;*/
/*}*/
/*.order_receiver {*/
/*    text-align: left;*/

/*    border-radius: 4px;*/
/*    padding: 8px;*/
/*    width:100%;*/
/*    margin-left: 16px;*/
/*    margin-right: 8px;*/

/*}*/
/*.order_receiver_name {*/
/*    padding: 0px 8px;*/
/*    font-size: 20px;*/
/*}*/
/*.order_receiver_address {*/
/*    padding: 8px;*/
/*    font-size: 16px;*/
/*    color: #7E7D7D;*/
/*}*/

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
    margin: 0;
    /*width: calc(100% - 32px);*/
}

/*.order .MuiDialogContent-root {*/
/*    padding: 4px ;*/
/*}*/

/*.label {*/
/*    color: #7E7D7D;*/
/*    font-size: 12px;*/
/*    padding-top: 8px;*/
/*    padding-left: 16px;*/
/*    margin-bottom: -6px;*/
/*}*/
/*.select {*/
/*    padding-top: 8px;*/
/*    padding-left: 16px;*/
/*    width:160px;*/
/*}*/
/*.picker {*/
/*    padding-left: 24px;*/
/*    width: 164px*/
/* }*/
/*.date_label {*/
/*    text-align: left;*/
/*    width: 150px;*/
/*    color: #7E7D7D;*/
/*    font-size: 12px;*/
/*    padding-top: 8px;*/
/*    padding-left: 0px;*/
/*    margin-bottom: -8px;*/
/*}*/
/*.search .quick-search {*/
/*    padding-top: 25px;*/
/*    padding-left: 12px;*/
/*}*/

/*.search .quick-search input{*/
/*    width: 140px;*/
/*    height: 34px;*/
/*    outline: none;*/
/*    border-radius: 4px;*/
/*    box-shadow: none;*/
/*    border: 1px solid rgb(204, 204, 204);*/
/*    padding-left: 34px;*/
/*    z-index: 0;*/
/*    font-size: 1em;*/
/*}*/

/*.search .search-icon {*/
/*    padding: 8px;*/
/*    display: flex;*/
/*    position: absolute;*/
/*    z-index: 1;*/
/*    color: rgb(204, 204, 204);*/
/*}*/

/*.search .quick-search {*/
/*    display: flex;*/
/*    width: 100%;*/
/*}*/
/*.order_header2 {*/
/*    padding-bottom: 8px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/
/*.cart_sum {*/
/*    display: flex;*/
/*    text-align: center;*/
/*    padding:8px;*/
/*    margin: 8px;*/
/*    margin-right: 24px;*/
/*    vertical-align: center;*/
/*    align-content: center;*/
/*    align-items: center;*/
/*    border-radius: 4px;*/
/*    cursor:pointer;*/
/*}*/

/*.cart_sum div {*/
/*    padding-left: 4px;*/
/*}*/

/*.cart_sum:hover {*/
/*    background-color: rgba(0, 0, 0, 0.04);*/
/*}*/
.MuiSlider-root {
    width:40% !important;
}
/*@media (min-width: 600px) {*/
/*    .general_table .MuiToolbar-regular {*/
/*       min-height: 0px !important;*/
/*    }*/
/*}*/

/*!*.general_table .MuiTableSortLabel-root.MuiTableSortLabel-active {*!*/
/*!*    color: white;*!*/
/*!*}*!*/

/*!*.general_table .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {*!*/
/*!*    color: white;*!*/
/*!*}*!*/
/*!*.general_table .MuiTableSortLabel-root:hover {*!*/
/*!*    color: white;*!*/
/*!*}*!*/

/*.general_table {*/
/*    max-width: 1200px;*/
/*}*/

/*.general_table .MuiTableRow-root.MuiTableRow-head:first-child th:first-child {*/
/*    text-align: center;*/
/*    border-top-left-radius: 4px;*/
/*}*/
/*.general_table .MuiTableRow-root.MuiTableRow-head:first-child th:last-child {*/
/*    text-align: center;*/
/*    border-top-right-radius: 4px;*/

/*}*/

/*.active_status {*/
/*    background-color: #e9f3e9;*/
/*    padding: 8px 12px;*/
/*    border-radius: 6px;*/
/*}*/
/*.inactive_status {*/
/*    background-color: #ffe9e9;*/
/*    padding: 8px 12px;*/
/*    border-radius: 6px;*/
/*}*/

/*.organization_row {*/
/*    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.14);*/
/*    padding: 8px 12px;*/
/*    margin-bottom: 4px;*/
/*    margin-top: 4px;*/
/*    border-radius:6px*/
/*}*/
.orders {
    padding-top: 30px;
    width: 100%
}

/*@media all and (min-width:0px) and (max-width: 320px) {*/
/*    .orders {*/
/*    padding-top: 15vh;!* put your css styles in here *!*/
/*    }*/
/*}*/

/*@media all and (min-width:0px) and (max-width: 810px) {*/
/*    .orders {*/
/*        padding-top: 10vh;!* put your css styles in here *!*/
/*    }*/
/*}*/
.admin {
    padding-top: 15px;
}
.MuiTabs-flexContainer {
    justify-content: center;
    align-content: center;
}
.inventory {
    padding-top: 30px;
}
.reports {
    width:100%;
}
.App {
  text-align: center;
  /*font-family:'Roboto', sans-serif;*/
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  background-color: #F9FAFC;
}

.flag-dropdown {
  display: none;
}

.buttons-nav {
    display: flex;
    /*margin-top: 20px;*/
    /*margin-left: 1470px;*/
}

.button-nav {
    color: black;
    padding: 12px;
    text-align: right;
}

.header a {
    color: #fff;
    text-decoration: none;
}

.header a:hover {
    color: #F2F2F2;
}

.MuiList-padding {
    padding: 0;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding-top: 0px;
}

.transparent_paper .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
}

.MuiButton-startIcon.MuiButton-iconSizeMedium {
    margin-right: 0px;
}

body {
    margin: 0;
    background: #151616;
    font-family: roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
    --bg:  white;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: #dadce1;
    color: var(--text-color);
    text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
    height: 60px;
    height: var(--nav-size);
    background-color: white;
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: 1px solid #474a4d;
    border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

/* <li> */
.nav-item {
    width: calc(60px * 0.8);
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon Button */
.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #ffffff;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.icon-button svg {
    fill: #757575;
    width: 24px;
    height: 24px;
}


/* Dropdown Menu */
.item {
    margin-bottom: 4px;
    margin-top: 4px;
    border-radius: 4px;
    transition: 0.4s;
    border:1px solid rgba(245, 245, 245, 0.35);
}

.item .clear {
    display: none;
}

.item:hover .clear {
    display: block;
    color:grey;
}

.item:hover .clear:hover {
    color: black;
}

.item:hover {
    background: whitesmoke;
    cursor: pointer;
}
.mark:hover {
    color: #00223D;
    text-decoration: underline;
    cursor: pointer;
}

.dropdown {
    position: relative;
    /*top: 58px;*/
    min-width: 400px;
    margin: 4px 8px 4px 2px;
    /*transform: translateX(-15%);*/
    background-color: white;
    background-color: var(--bg);
    border: 1px solid whitesmoke;
    border-radius: 2px;
    padding: 1rem;
    overflow: hidden;
    transition: height 500ms ease;
    transition: height var(--speed) ease;
    box-shadow: 0px 2px 8px -2px rgba(9, 30, 66, .2);
    /*rgb(9 30 66 / 31%) 0px 0px 1px;*/
}

.menu {
    width: 100%;
}

.menu-item {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border-radius: var(--border-radius);
    transition: background 500ms;
    transition: background var(--speed);
    padding: 1rem;
    background: whitesmoke;
    margin-bottom: 12px;
    text-color:black;
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
    -webkit-filter: none;
            filter: none;
}

.menu-item:hover {
    background-color: lightgrey;
}

.icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    -ms-transform: translateX(-110%);
        transform: translateX(-110%);
}
.menu-primary-enter-active {
    -ms-transform: translateX(0%);
        transform: translateX(0%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    -ms-transform: translateX(-110%);
        transform: translateX(-110%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
}


.menu-secondary-enter {
    -ms-transform: translateX(110%);
        transform: translateX(110%);
}
.menu-secondary-enter-active {
    -ms-transform: translateX(0%);
        transform: translateX(0%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
}
.menu-secondary-exit {
    position: absolute;
}
.menu-secondary-exit-active {
    -ms-transform: translateX(110%);
        transform: translateX(110%);
    transition: all 500ms ease;
    transition: all var(--speed) ease;
}

.container2 {
    padding: 10px;
    border: 1px solid black;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    display: block;
    height: 200px;
    margin-top: 1%;
    border-radius: 10px;
}

.headline {
    /* position: absolute; */
    font-size: 1.2em;
    padding: 0.8em;
    display: block;
    /* background-color: rgb(186, 77, 77); */
    text-align: left;
    /* margin-top: 50px; */
    /* padding-top: 100px; */
}

.addpatients {
    /* display: flex; */
    margin-top: 48px;
    width: 100%;
}

.container2 input:focus {
    outline: none;
    border: none;
}

.inputs {
    position: absolute;
}

.MuiTooltip-tooltip {
    font-size: 15px !important;
}
.inventory {
    padding-top: 30px;
}
.reports {
    width:100%;
}
