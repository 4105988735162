
.order_container {
    /*background-color: #f44336;*/
min-height: 300px;

}
/*.order_header {*/

/*    display: flex;*/
/*    !*justify-content: space-between;*!*/
/*}*/

/*.order_title {*/
/*    border-radius: 4px;*/
/*    padding: 8px 12px;*/
/*    margin-left: 8px;*/
/*    font-size: 20px;*/
/*    width:350px;*/
/*    height: 24px;*/
/*}*/
/*.order_receiver {*/
/*    text-align: left;*/

/*    border-radius: 4px;*/
/*    padding: 8px;*/
/*    width:100%;*/
/*    margin-left: 16px;*/
/*    margin-right: 8px;*/

/*}*/
/*.order_receiver_name {*/
/*    padding: 0px 8px;*/
/*    font-size: 20px;*/
/*}*/
/*.order_receiver_address {*/
/*    padding: 8px;*/
/*    font-size: 16px;*/
/*    color: #7E7D7D;*/
/*}*/

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
    margin: 0;
    /*width: calc(100% - 32px);*/
}

/*.order .MuiDialogContent-root {*/
/*    padding: 4px ;*/
/*}*/

/*.label {*/
/*    color: #7E7D7D;*/
/*    font-size: 12px;*/
/*    padding-top: 8px;*/
/*    padding-left: 16px;*/
/*    margin-bottom: -6px;*/
/*}*/
/*.select {*/
/*    padding-top: 8px;*/
/*    padding-left: 16px;*/
/*    width:160px;*/
/*}*/
/*.picker {*/
/*    padding-left: 24px;*/
/*    width: 164px*/
/* }*/
/*.date_label {*/
/*    text-align: left;*/
/*    width: 150px;*/
/*    color: #7E7D7D;*/
/*    font-size: 12px;*/
/*    padding-top: 8px;*/
/*    padding-left: 0px;*/
/*    margin-bottom: -8px;*/
/*}*/
/*.search .quick-search {*/
/*    padding-top: 25px;*/
/*    padding-left: 12px;*/
/*}*/

/*.search .quick-search input{*/
/*    width: 140px;*/
/*    height: 34px;*/
/*    outline: none;*/
/*    border-radius: 4px;*/
/*    box-shadow: none;*/
/*    border: 1px solid rgb(204, 204, 204);*/
/*    padding-left: 34px;*/
/*    z-index: 0;*/
/*    font-size: 1em;*/
/*}*/

/*.search .search-icon {*/
/*    padding: 8px;*/
/*    display: flex;*/
/*    position: absolute;*/
/*    z-index: 1;*/
/*    color: rgb(204, 204, 204);*/
/*}*/

/*.search .quick-search {*/
/*    display: flex;*/
/*    width: 100%;*/
/*}*/
/*.order_header2 {*/
/*    padding-bottom: 8px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/
/*.cart_sum {*/
/*    display: flex;*/
/*    text-align: center;*/
/*    padding:8px;*/
/*    margin: 8px;*/
/*    margin-right: 24px;*/
/*    vertical-align: center;*/
/*    align-content: center;*/
/*    align-items: center;*/
/*    border-radius: 4px;*/
/*    cursor:pointer;*/
/*}*/

/*.cart_sum div {*/
/*    padding-left: 4px;*/
/*}*/

/*.cart_sum:hover {*/
/*    background-color: rgba(0, 0, 0, 0.04);*/
/*}*/