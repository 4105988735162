body {
    margin: 0;
    background: #151616;
    font-family: roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
    --bg:  white;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: var(--text-color);
    text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

/* <li> */
.nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon Button */
.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #ffffff;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.icon-button:hover {
    filter: brightness(1.2);
}

.icon-button svg {
    fill: #757575;
    width: 24px;
    height: 24px;
}


/* Dropdown Menu */
.item {
    margin-bottom: 4px;
    margin-top: 4px;
    border-radius: 4px;
    transition: 0.4s;
    border:1px solid rgba(245, 245, 245, 0.35);
}

.item .clear {
    display: none;
}

.item:hover .clear {
    display: block;
    color:grey;
}

.item:hover .clear:hover {
    color: black;
}

.item:hover {
    background: whitesmoke;
    cursor: pointer;
}
.mark:hover {
    color: #00223D;
    text-decoration: underline;
    cursor: pointer;
}

.dropdown {
    position: relative;
    /*top: 58px;*/
    min-width: 400px;
    margin: 4px 8px 4px 2px;
    /*transform: translateX(-15%);*/
    background-color: var(--bg);
    border: 1px solid whitesmoke;
    border-radius: 2px;
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    -webkit-box-shadow: 1px 1px 5px -2px #000000;
    box-shadow: 0px 2px 8px -2px rgba(9, 30, 66, .2);
    /*rgb(9 30 66 / 31%) 0px 0px 1px;*/
}

.menu {
    width: 100%;
}

.menu-item {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 1rem;
    background: whitesmoke;
    margin-bottom: 12px;
    text-color:black;
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
    filter: none;
}

.menu-item:hover {
    background-color: lightgrey;
}

.icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}


.menu-secondary-enter {
    transform: translateX(110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-secondary-exit {
    position: absolute;
}
.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}
